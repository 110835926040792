import tinymce from "tinymce"
/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default';
// import './tinymce/icons'

/* A theme is also required */
import 'tinymce/themes/silver';

/* Import the skin */
// import 'tinymce/skins/ui/oxide/skin.css';
import './tinymce/skin.min.css'

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/spellchecker';

/* Import premium plugins */
/* NOTE: Download separately and add these to /src/plugins */
/* import './plugins/checklist/plugin'; */
/* import './plugins/powerpaste/plugin'; */
/* import './plugins/powerpaste/js/wordimport'; */

/* Import content css */
// import contentUiCss from 'tinymce/skins/ui/oxide/content.css';
// import contentCss from 'tinymce/skins/content/default/content.css';

// import 'tinymce/skins/ui/oxide/content.css';
// import 'tinymce/skins/ui/oxide/content.inline.css';
// import 'tinymce/skins/content/default/content.css';

let tinymceVue = require('@tinymce/tinymce-vue').default
tinymceVue.model = {
	prop: "value",
	event: "input"
}
window.tinymceVue = tinymceVue
Vue.component("editor", tinymceVue)
